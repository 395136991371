import React, { useEffect } from "react"
import Layout from "../components/Layout/layout"
import ListProducts from "../components/Products/ProductList"
import SEO from "../components/seo"
import Loading from "../components/Loading"

const Shop = ({ data, location }) => {
  const siteTitle = `Unser Online Shop`
  const handleScroll = e => {
    const siteHeader = document.querySelector(".site-header")
    const linkItem = document.querySelectorAll(".header-link__item")
    const element = e.target.firstElementChild
    if (element.scrollTop > 0) {
      // siteHeader.style.boxShadow = "0 0 1px 1px #cecece"
      siteHeader.style.background = "black"
      ;[...linkItem].forEach(item => (item.style.color = "white"))
    } else {
      siteHeader.style.background = "unset"
      // ;[...linkItem].forEach(item => (item.style.color = "black"))
      // siteHeader.style.boxShadow = "unset"
    }
  }

  useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "black"

    // window.addEventListener("scroll", handleScroll)
    // return () => {
    //   const siteHeader = document.querySelector(".site-header")

    //   siteHeader.style.background = "black"
    //   window.removeEventListener("scroll", handleScroll)
    // }
  }, [])
  return (
    <Layout>
      <SEO title="Shopping Online" />

      <ListProducts />
    </Layout>
  )
}

export default Shop
